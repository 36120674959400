@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

.title {
  @include font-style-h4($font-weight-medium);
  color: var(--text-10);
  margin: 0;
}

.container {
  flex-grow: 1;
  padding: $content-indent;
}

.spinnerWrapper,
.noExternalGames,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 140px 0;
}

.noExternalGames {
  color: var(--text-30);
}

.error {
  color: var(--system-error);
}

.tilesWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 24px;
  margin: 0;
  margin-top: 24px;
  padding: 0;
  list-style-type: none;
}
